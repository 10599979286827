import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import React from "react";
import Logo from "../../assets/images/logo-light-sm.png";

const Footer: React.FC = () => {
  return (
    <footer className="bg-dark py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src={Logo} alt="logo" className="logo-dark" height={50} />
            <p className="text-light text-opacity-50 mt-4">
              Foundation Labs é uma empresa de tecnologia que desenvolve
              soluções para a área de robótica e automação.
            </p>
            <ul className="social-list list-inline mt-3">
              <li className="list-inline-item text-center">
                <a
                  href="https://www.instagram.com/foundation.lab/"
                  className=" border-light text-light d-flex align-items-center justify-content-center"
                >
                  <InstagramIcon scale={24} />
                </a>
              </li>
              <li className="list-inline-item text-center">
                <a
                  href="https://www.linkedin.com/company/foundationlab/"
                  className=" border-light text-light d-flex align-items-center justify-content-center"
                >
                  <LinkedInIcon scale={24} />
                </a>
              </li>
              <li className="list-inline-item text-center">
                <a
                  href="mailto:contato@foundationlabs.com.br"
                  className=" border-light text-light d-flex align-items-center justify-content-center"
                >
                  <EmailIcon scale={24} />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-4 mt-3 mt-lg-0"></div>
          <div className="col-lg-2 col-md-4 mt-3 mt-lg-0"></div>
          <div className="col-lg-2 col-md-4 mt-3 mt-lg-0"></div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="mt-5">
              <p className="text-light text-opacity-50 mt-4 text-center mb-0">
                © 2022 - {new Date().getFullYear()} Foundation Labs - Todos os
                direitos reservados
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
