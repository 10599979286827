import React from "react";
import Robot from "../../assets/images/robo.svg";
import Video from "../../assets/videos/fumaca.mp4";

// import { Container } from './styles';

const Box1: React.FC = () => {
  const [load, setLoad] = React.useState(false);
  return (
    <section className="hero-section">
      <video
        autoPlay
        muted
        loop
        id="myVideo"
        onLoadedData={() => setLoad(true)}
        className={"w-100 fade-in " + (load ? "video-load" : "")}
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div className="video-box"></div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="mt-md-4">
              <a
                href="https://app.foundationlabs.com.br/"
                className="d-flex justify-content-start align-items-center text-decoration-none"
              >
                <span className="badge bg-danger font-13 rounded-pill">
                  Novo
                </span>
                <span className="text-white-50 ms-1">
                  Controle nossos robôs da sua casa
                </span>
              </a>
              <h2 className="text-white fw-normal mb-4 mt-3 lh-base">
                Somos um laboratório de eletrônica
              </h2>
              <p className="mb-4 font-16 text-white-50">
                Nossa meta é sempre estar nas fundações da eletrônica, robótica
                e programação. Somos apaixonados pelo que fazemos e o que amamos
                é entender profundamente o funcionamento das coisas. Nosso
                objetivo é contribuir para o avanço da tecnologia, desenvolvendo
                projetos e produtos que possam transformar a vida das pessoas.
              </p>
              <a
                href="#projects"
                className="btn btn-lg font-16 btn-outline-light"
              >
                Veja nosso projetos
              </a>
            </div>
          </div>
          <div className="col-md-5 offset-md-2">
            <div className="text-md-end mt-3 mt-md-0">
              <img src={Robot} alt="a" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Box1;
