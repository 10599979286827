import {
  ArrowUp,
  Blur,
  CloudLightning,
  Code,
  Cup,
  House,
  Money2,
  Shield,
  Star,
  Truck,
} from "iconsax-react";
import React from "react";

const Dots: React.FC = () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="row py-4">
          <div className="col-lg-12">
            <div className="text-center">
              <h1 className="mt-0">
                <Cup size={48} />
              </h1>
              <h3>
                Nosso <span className="text-success">diferencial</span>
              </h3>
              <p className="text-muted mt-2">
                Trazemos tecnologia de ponta a fim de baratear os custos de
                entregas para todos
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <Truck size="24" />
                </span>
              </div>
              <h4 className="mt-3">Eficiência Operacional</h4>
              <p className="text-muted mt-2 mb-0">
                Robôs podem ser programados para otimizar rotas, proporcionando
                entregas mais rápidas e eficientes.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <Money2 size="24" />
                </span>
              </div>
              <h4 className="mt-3">Redução de Custos</h4>
              <p className="text-muted mt-2 mb-0">
                A automação reduz a dependência de mão de obra humana,
                diminuindo os custos operacionais.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <ArrowUp size="24" />
                </span>
              </div>
              <h4 className="mt-3">Precisão e Consistência</h4>
              <p className="text-muted mt-2 mb-0">
                Robôs podem realizar entregas com alta precisão, proporcionando
                uma experiência consistente.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <Blur size="24" />
                </span>
              </div>
              <h4 className="mt-3">Sustentabilidade Ambiental</h4>
              <p className="text-muted mt-2 mb-0">
                Robôs podem ser alimentados por energia elétrica, contribuindo
                para operações mais sustentáveis.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <Code size="24" />
                </span>
              </div>
              <h4 className="mt-3">Integração com Tecnologia</h4>
              <p className="text-muted mt-2 mb-0">
                Oferecemos rastreamento em tempo real e integração com
                inteligência artificial para otimizar nossas operações.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <House size="24" />
                </span>
              </div>
              <h4 className="mt-3">Adaptação a Ambientes Urbanos</h4>
              <p className="text-muted mt-2 mb-0">
                Desenvolvemos robôs pequenos e ágeis para navegar eficientemente
                em áreas urbanas.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <Shield size="24" />
                </span>
              </div>
              <h4 className="mt-3">Segurança e Proteção</h4>
              <p className="text-muted mt-2 mb-0">
                Implementamos medidas avançadas de segurança nos robôs para
                proteger as entregas contra roubos.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <Star size="24" />
                </span>
              </div>
              <h4 className="mt-3">Personalização da Experiência do Cliente</h4>
              <p className="text-muted mt-2 mb-0">
                Oferecemos opções de agendamento flexíveis e serviços premium
                para uma entrega autônoma personalizada.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title text-success bg-success-lighten rounded-circle">
                  <CloudLightning size="24" />
                </span>
              </div>
              <h4 className="mt-3">Inovação Tecnológica</h4>
              <p className="text-muted mt-2 mb-0">
                Estamos constantemente atualizando nossos robôs com as mais
                recentes inovações tecnológicas para garantir eficiência e
                confiabilidade contínuas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dots;
