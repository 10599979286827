import {
  Brush,
  ChemicalGlass,
  Flag,
  Harmony,
  MedalStar,
  RepeateMusic,
  Settings,
} from "iconsax-react";
import React from "react";

// import { Container } from './styles';

const Box2: React.FC = () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="row py-4">
          <div className="col-lg-12">
            <div className="text-center">
              <h1 className="mt-0">
                <MedalStar size={48} />
              </h1>
              <h3>
                Um pouco do que <span className="text-primary">fazemos</span>
              </h3>
              <p className="text-muted mt-2">
                Nós somos um grupo de pessoas que gostam de criar coisas, e
                robôs são uma das nossas paixões.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <Settings size="24" />
                </span>
              </div>
              <h4 className="mt-3">Montagem de robôs</h4>
              <p className="text-muted mt-2 mb-0">
                Montamos robôs para provas de conceito e testes de novas ideias.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <Harmony size="24" />
                </span>
              </div>
              <h4 className="mt-3">Sistema de controle</h4>
              <p className="text-muted mt-2 mb-0">
                Criamos nosso próprio sistema de controle de robôs, que pode ser
                operado de qualquer lugar do mundo.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <Flag size="24" />
                </span>
              </div>
              <h4 className="mt-3">Tecnologia nacional</h4>
              <p className="text-muted mt-2 mb-0">
                Sempre procuramos reutilizar ao máximo as peças disponíveis no
                Brasil, muitas vezes montando equipamentos do zero.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <Brush size="24" />
                </span>
              </div>
              <h4 className="mt-3">Se não pode comprar, crie!</h4>
              <p className="text-muted mt-2 mb-0">
                Fabricamos muitos dos nossos próprios equipamentos, incluindo
                uma impressora 3D e CNC
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <RepeateMusic size="24" />
                </span>
              </div>
              <h4 className="mt-3">Tentativa e error</h4>
              <p className="text-muted mt-2 mb-0">
                Tentamos de tudo; se não sabemos, aprendemos, e se não temos,
                nós mesmos construímos.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="text-center p-2 p-sm-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <ChemicalGlass size="24" />
                </span>
              </div>
              <h4 className="mt-3">Manutenção de equipamentos</h4>
              <p className="text-muted mt-2 mb-0">
                Realizamos a manutenção de equipamentos de laboratório antigos e
                clássicos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Box2;
