import { ArrowRight2 } from "iconsax-react";
import React from "react";

import Control1 from "../../assets/images/control.jpg";
import Jhonny from "../../assets/images/jhonny.jpg";
import Jhonny2 from "../../assets/images/jhonny2.jpg";
import { Carousel } from "react-bootstrap";

// import { Container } from './styles';

const Box4: React.FC = () => {
  return (
    <section className="py-5" id="projects">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <h3>
                Veja nossos <span className="text-primary">projetos</span>
              </h3>
              <p className="text-muted mt-2">
                Alguns de nossos projetos e ideias que trabalhamos no dia a dia
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-2 py-5 align-items-center">
          <div className="col-lg-5 col-md-6">
            <img
              src={Control1}
              className="img-fluid rounded-1 border"
              alt="a"
            />
          </div>
          <div className="col-lg-6 offset-md-1 col-md-5">
            <h3 className="fw-normal">Foundation Control</h3>
            <p className="text-muted mt-3">
              É um painel de controle avançado para robôs, projetado para
              permitir o controle remoto via Internet. Com ele, você pode
              visualizar o que o seu robô está vendo e controlar seus movimentos
              em tempo real, onde quer que você esteja.
            </p>
            <div className="mt-4">
              <p className="text-muted">
                <ArrowRight2 size="32" color="#FF8A65" variant="Bold" />{" "}
                Personalização para diferentes tipos de robôs.
              </p>
              <p className="text-muted">
                <ArrowRight2 size="32" color="#FF8A65" variant="Bold" />{" "}
                Controle total, independentemente de sua localização.
              </p>
              <p className="text-muted">
                <ArrowRight2 size="32" color="#FF8A65" variant="Bold" /> Acesso
                a telemetria completa.
              </p>
              <p className="text-muted">
                <ArrowRight2 size="32" color="#FF8A65" variant="Bold" /> Suporte
                para controle de videogame,
                <span className="text-primary"> incluindo volante</span>
              </p>
            </div>
          </div>
        </div>
        <div className="row pb-3 pt-5 align-items-center">
          <div className="col-lg-6 col-md-5">
            <h3 className="fw-normal">Projeto Johnny - O Robô Explorador</h3>
            <p className="text-muted mt-3">
              Inicialmente concebido como um robô explorador de ambientes, o
              Projeto Johnny evoluiu ao longo do tempo para se tornar um centro
              de pesquisa e desenvolvimento de tecnologias de ponta em robótica.
            </p>
            <p className="text-muted">
              Agora, o Johnny é muito mais do que um mero explorador. Com a
              capacidade de operar remotamente de qualquer lugar do mundo, ele
              permite que seus operadores vejam e interajam com o ambiente
              circundante por meio de sua câmera de quatro eixos, além de
              controlar o robô à distância.
            </p>
            <div className="mt-4">
              <p className="text-muted">
                <ArrowRight2 size="32" color="#FF8A65" variant="Bold" />{" "}
                Controle a distância
              </p>
              <p className="text-muted">
                <ArrowRight2 size="32" color="#FF8A65" variant="Bold" />{" "}
                Interage com as pessoas ao redor por meio de sua tela
              </p>
              <p className="text-muted">
                <ArrowRight2 size="32" color="#FF8A65" variant="Bold" /> Camera
                se move em 4 eixos
              </p>
              <p className="text-muted">
                <ArrowRight2 size="32" color="#FF8A65" variant="Bold" />{" "}
                Transmite sentimentos às pessoas ao redor
              </p>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 offset-md-1">
            <Carousel>
              <Carousel.Item>
                <img
                  src={Jhonny}
                  className="img-fluid rounded-1 border"
                  alt="robô olhando para a camera"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={Jhonny2}
                  className="img-fluid rounded-1 border"
                  alt="robô olhando para a camera com cara de mal"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <h1 className="mt-0">
                <Lovely size="48" />
              </h1>
              <h3>
                O <span className="text-danger">amor</span> está na nossa
                essência
              </h3>
              <p className="text-muted mt-2">
                Desde o primeiro dia, todos os robôs são projetados para
                transmitirem sentimentos às pessoas ao redor. <br /> Ninguém
                aqui é uma caixa de metal sem vida, todos nós temos um ar de
                humanidade.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Box4;
