import React from "react";
import HeroSection from "./HeroSection";
import ListFeatures from "./ListFeatures";
import ListProjects from "./ListProjects";
import ListEvents from "./ListEvents";

import "../../assets/stylesheets/main.scss";

function App() {
  return (
    <div>
      <HeroSection />
      <ListFeatures />
      <ListProjects />
      <ListEvents />
    </div>
  );
}

export default App;
