import { Ticket } from "iconsax-react";
import React from "react";
import RWI from "../../assets/images/RIW.jpg";
import Hacktudo from "../../assets/images/hacktudo.jpg";

// import { Container } from './styles';

const Box5: React.FC = () => {
  return (
    <section className="py-5 bg-light-lighten border-top border-bottom border-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <h1 className="mt-0">
                <Ticket size="48" />
              </h1>
              <h3>
                Alguns <span className="text-primary">eventos</span> que já
                fomos
              </h3>
              <p className="text-muted mt-2">
                Alguns eventos que já participamos e que iremos participar em um
                futuro próximo
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="card d-block">
                <img
                  className="card-img-top"
                  src={RWI}
                  alt="Rio Innovation Week 2023"
                />
                <div className="card-body">
                  <h5 className="card-title">Rio Innovation Week 2023</h5>
                  <p className="card-text">
                    O Rio Innovation Week 2023 é um dos maiores eventos de
                    tecnologia realizados na América Latina. Participamos dos
                    três dias do evento e foi ótimo.
                    <br />
                    Tivemos a oportunidade de conhecer muitas pessoas e fazer
                    muitos contatos. Foi uma experiência incrível e esperamos
                    participar de mais eventos como este.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="d-block card ">
                <img
                  className="card-img-top"
                  src={Hacktudo}
                  alt="Hacktudo 2023"
                />
                <div className="card-body">
                  <h5 className="card-title">Hacktudo 2023</h5>
                  <p className="card-text">
                    Um dos maiores festivais de cultura digital do país, o
                    Hacktudo 2023, reúne em um só lugar, durante dois dias,
                    workshops, oficinas, feira maker, hackathon e muito mais.
                    <br />
                    Estivemos presentes nos dois dias do evento e foi incrível.
                    A equipe foi muito amigável conosco e até{" "}
                    <a href="https://www.instagram.com/p/CzMMOiUs9ZQ/">
                      aparemos no material publicitário deles
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Box5;
