import React from "react";
import { DirectRight } from "iconsax-react";
import Logo from "../../assets/images/logo-light-sm.png";

const Nav: React.FC = () => {
  return (
    <nav className="navbar navbar-expand-lg py-lg-3 navbar-dark">
      <div className="container active">
        {/* logo */}
        <a href="/" className="navbar-brand me-lg-5">
          <img src={Logo} alt="logo" className="logo-dark" width={40} />
        </a>
        {/* menus */}
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          {/* left menu */}
          <ul className="navbar-nav me-auto align-items-center active">
            <li className="nav-item mx-lg-1">
              <a className="nav-link active" href="/">
                Home
              </a>
            </li>
            <li className="nav-item mx-lg-1">
              <a className="nav-link active" href="/#projects">
                Projetos
              </a>
            </li>
            <li className="nav-item mx-lg-1">
              <a className="nav-link active" href="/delivery">
                Delivery
              </a>
            </li>
            <li className="nav-item mx-lg-1">
              <a
                className="nav-link active"
                href="https://www.instagram.com/foundation.lab/"
              >
                Contato
              </a>
            </li>
          </ul>
          {/* right menu */}
          <ul className="navbar-nav ms-auto align-items-center">
            <li className="nav-item me-0">
              <a
                href="https://app.foundationlabs.com.br/"
                target="_blank"
                className="btn btn-sm btn-light rounded-pill d-flex align-items-center justify-content-center px-3 py-1"
                rel="noreferrer"
              >
                <DirectRight size="16" className="me-2" />
                Teste agora
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
