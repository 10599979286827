import React from "react";
import DroneDelivery from "../../assets/images/drone_delivery.svg";
import Dots from "./Dots";

const Delivery: React.FC = () => {
  return (
    <div>
      <section className="hero-section hero-section-after">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="mt-md-4">
                <a
                  href="https://app.foundationlabs.com.br/"
                  className="d-flex justify-content-start align-items-center text-decoration-none"
                >
                  <span className="badge bg-danger font-13 rounded-pill">
                    Novo
                  </span>
                  <span className="text-white-50 ms-1">
                    Conheça nossos robôs de delivery
                  </span>
                </a>
                <h2 className="text-white fw-normal mb-4 mt-3 lh-base">
                  Revolucionando o Setor de Entregas no Brasil
                </h2>
                <p className="mb-4 font-16 text-white-50">
                  A Foundation está inovando no mercado de delivery de alimentos
                  no Brasil, introduzindo as últimas tecnologias para
                  proporcionar a melhor experiência aos clientes e o melhor
                  custo para nossos parceiros. Estamos comprometidos em
                  transformar a maneira como as entregas são feitas,
                  incorporando eficiência, sustentabilidade e conveniência em
                  cada etapa do processo.
                </p>
              </div>
            </div>
            <div className="col-md-5 offset-md-2">
              <div className="text-md-end mt-3 mt-md-0">
                <img src={DroneDelivery} alt="delivery" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dots />
    </div>
  );
};

export default Delivery;
